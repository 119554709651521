export const WORDLE_TITLE = 'Cádiz Wordle'
export const ABOUT_GAME_MESSAGE = 'Sobre Cádiz Wordle'

export const HAS_GANADO = 'Has ganado'
export const WIN_MESSAGES = ['' ]
export const HAS_FALLADO = 'Has fallado'
export const LOST_MESSAGES = []

export const HASHTAG = '#CadizWorlde'
export const URL = 'https://wordlecadiz.grupoarsenio.com'
export const EMOJI_OK = '🟩'
export const EMOJI_PRESENT = '🟨'
export const EMOJI_ABSENT = '⬜'

export const HAY_BLOG = true
export const HAY_BANNER_PUBLI_EN_MODAL = false

export const REVELAMOS_SOLUCION = true
export const BANNER_ACIERTO = 1
export const BANNER_FALLO = 1
export const PUBLI_MODAL_ACIERTO_URL = ''
export const PUBLI_MODAL_ACIERTO_TEXTO = ''
export const HAY_ADSENSE = false
export const ADSENSE_CA = ""
export const ADSENSE_BANN_TECLADO = ""



export const AUTOR_TEXTO_PREVIO = 'Juego adaptado por'
export const AUTOR_TEXTO_AUTOR = '​Writy Copywriting'
export const AUTOR_TEXTO_URL = "https://writy.es"


export const INFO_DESCRIPCION1 = "Adivina la palabra en 6 intentos. Después de cada intento, el color de las letras cambiará para indicarte si estás cerca o lejos de la solución."
export const INFO_DESCRIPCION2 = "Siempre será una palabra relacionada con Cádiz: calles, personajes pueblos, barrios..."
export const INFO_PRIVACIDAD_ANTES = "Al jugar estás aceptando la"
export const INFO_PRIVACIDAD_PRIVA = "política de privacidad"
export const INFO_PRIVACIDAD_URL = "https://www.grupoarsenio.com/politica-privacidad/"


export const URL_CONTACTO = "mailto:wordlex@writy.es"
export const URL_BLOG = "https://www.grupoarsenio.com/category/cadiz-wordle/?utm_source=wordlecadiz&utm_medium=juego&utm_campaign=visitas-soluciones&utm_id=soluciones"
export const ACIERTO_TEXTO_BLOG = "+ info sobre la palabra de cada día en el blog"
export const FALLO_TEXTO_BLOG1 = "+ info sobre la palabra de cada día en el blog"
export const FALLO_TEXTO_BLOG2 = ""


export const CONTACTO_TEXTO_ANTES = "Puedes enviar tus comentarios, errores y sugerencias de palabras a través del email"
export const CONTACTO_TEXTO_TXT = "wordlex@writy.es"

export const SETTINGS_MODONOCHE_TEXTO = "Modo noche"
export const SETTINGS_MODONOCHE_DES = "Modo noche"
export const SETTINGS_MODODALTONICOS_TEXTO = "Modo daltónicos"
export const SETTINGS_MODODALTONICOS_DES = "Cambia los colores de las letras para ayudar a personas con esta dificultad"

export const BOTONSOLUCIONES_TEXTO = "SOLUCIONES"

export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
  export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `La palabra era ${solution}. Mañana tendrás una nueva palabra`
  export const GAME_COPIED_MESSAGE = 'Juego copiado al portapapeles'
export const STATISTICS_TITLE = 'Estadísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribución'
export const NEW_WORD_TEXT = 'Nueva palabra para jugar en '
export const SHARE_TEXT = 'COMPARTIR'
export const TOTAL_TRIES_TEXT = 'Intentos totales'
export const SUCCESS_RATE_TEXT = 'Porcentaje de aciertos'
export const CURRENT_STREAK_TEXT = 'Racha actual'
export const BEST_STREAK_TEXT = 'Mejor racha'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Te faltan letras, deben ser 5.'
export const WORD_NOT_FOUND_MESSAGE = 'Palabra no encontrada en nuestro diccionario'
export const ENTER_TEXT = 'Intro'
export const DELETE_TEXT = 'Borrar'
export const HARD_MODE_ALERT_MESSAGE =
  'Hard Mode can only be enabled at the start!'
export const HARD_MODE_DESCRIPTION =
  'Any revealed hints must be used in subsequent guesses'