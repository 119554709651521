export const WORDS = [
   'cadiz',
   'cuple',
   'sieso',
   'kichi',
   'erizo',
   'jibia',
   'bajio',
   'batea',
   'masca',
   'jiñao',
   'paseo',
   'punta',
   'pisha',
   'killo',
   'killa',
   'adobo',
   'puñao',
   'pesha',
   'frito',
   'patio',
   'jarto',
   'salto',
   'playa',
   'arena',
   'viaje',
   'bicho',
   'beata',
   'bamba',
   'regla',
   'choco',
   'sacao',
   'ajola',
   'canto',
   'janda',
   'bache',
   'bollo',
   'bulla',
   'costo',
   'cucos',
   'gilao',
   'sepli',
   'jamar',
   'siesa',
   'yampa',
   'toque',
   'guasa',
   'kiski',
   'merla',
   'pollo',
   'mequi',
   'pulpo',
   'cuaja',
   'toyon',
   'ruina',
   'apipa',
   'julay',
   'ropia',
   'tosta',
   'pelua',
   'cutre',
   'balde',
   'grima',
   'inchi',
   'vejer',
   'jamba',
   'gache',
   'chulo',
   'torta',
   'fuñar',
   'bequi',
   'peman',
   'piola',
   'picle',
   'cuajo',
   'ciezo',
   'breva',
   'macho',
   'combi',
   'mebli',
   'rosco',
   'chuar',
   'traja',
   'machi',
   'guiri',
   'tajao',
   'jarta',
   'jarta',
   'pecha',
   'guita',
   'cagao',
   'vaina',
   'angua',
   'leima',
   'polea',
   'cundi',
   'ronea',
   'lejia',
   'jirda',
   'nacle',
   'tumbo',
   'terno',
   'turda',
   'datil',
   'petao',
   'rasca',
   'borde',
   'lidia',
   'fleti',
   'cebon',
   'añejo',
   'velon',
   'añada',
   'mocho',
   'aguja',
   'fajao',
   'braña',
   'rochi',
   'aloba',
   'capon',
   'pompa',
   'rubio',
   'pujar',
   'barco',
   'tonga',
   'bocao',
   'guijo',
   'grana',
   'testa',
   'bocoy',
   'hacho',
   'gachi',
   'brama',
   'llave',
   'rasco',
   'corna',
   'pella',
   'ansia',
   'huesa',
   'decir',
   'peluo',
   'frita',
   'safio',
   'tapon',
   'soleo',
   'tollo',
   'lezna',
   'linde',
   'kaire',
   'borra',
   'mosca',
   'dones',
   'saina',
   'menea',
   'aluda',
   'barlu',
   'telar',
   'beber',
   'volao',
   'azaon',
   'linio',
   'tapeo',
   'ripio',
   'flama',
   'media',
   'pecho',
   'calzo',
   'libar',
   'apero',
   'abono',
   'echar',
   'moca',
   'casca',
   'cesto',
   'saeta',
   'cispa',
   'circo',
   'criba',
   'marca',
   'zueco',
   'zofra',
   'manso',
   'murga',
   'rejia',
   'abeja',
   'monta',
   'matao',
   'sarta',
   'harca',
   'balsa',
   'chata',
   'lucio',
   'penco',
   'badil',
   'meano',
   'rueda',
   'plaza',
   'yezgo',
   'torva',
   'vacio',
   'bardo',
   'bolos',
   'piara',
   'panal',
   'hurta',
   'cueva',
   'alamo',
   'sable',
   'nanai',
   'cruza',
   'brasa',
   'grano',
   'torno',
   'vasar',
   'huero',
   'jarro',
   'apear',
   'burda',
   'tonel',
   'culon',
   'aznar',
   'harto',
   'muela',
   'tolva',
   'carzo',
   'piñon',
   'mojea',
   'papeo',
   'aliso',
   'cabra',
   'garza',
   'bocin',
   'metia',
   'boton',
   'potro',
   'corza',
   'carro',
   'liñar',
   'ligao',
   'turco',
   'agraz',
   'arido',
   'manca',
   'ceron',
   'dulce',
   'lante',
   'pozos',
   'rolon',
   'zotea',
   'marto',
   'noque',
   'caoba',
   'acial',
   'ojala',
   'laina',
   'baila',
   'ruame',
   'chopa',
   'pison',
   'vedao',
   'chivo',
   'tonta',
   'coceo',
   'flojo',
   'arana',
   'cucar',
   'cacha',
   'tejar',
   'camal',
   'diego',
   'negra',
   'bombo',
   'cocer',
   'anega',
   'racha',
   'angel',
   'binar',
   'metio',
   'censo',
   'piton',
   'reata',
   'raspa',
   'ligar',
   'armao',
   'brote',
   'telon',
   'tormo',
   'penca',
   'carna',
   'troza',
   'aojar',
   'diñar',
   'chapu',
   'yesca',
   'tosca',
   'verde',
   'vieja',
   'redil',
   'lamer',
   'venda',
   'lleno',
   'uciao',
   'picon',
   'poeta',
   'pavia',
   'legua',
   'oliva',
   'bruja',
   'marea',
   'cinta',
   'caiya',
   'cante',
   'basto',
   'sorea',
   'almud',
   'zorro',
   'cates',
   'jinco',
   'pezon',
   'zarzo',
   'sorgo',
   'lacio',
   'quema',
   'reloj',
   'picao',
   'gayuo',
   'jalon',
   'chazo',
   'porta',
   'jurel',
   'parra',
   'chala',
   'coger',
   'canoa',
   'lanua',
   'arrea',
   'misto',
   'zafio',
   'caldo',
   'añino',
   'laera',
   'yedra',
   'bicha',
   'rocia',
   'alban',
   'monja',
   'antie',
   'banco',
   'quija',
   'pioja',
   'colga',
   'garum',
   'pajar',
   'casco',
   'jarre',
   'farda',
   'canio',
   'darse',
   'verea',
   'tasca',
   'cardo',
   'zulla',
   'gozne',
   'balar',
   'redor',
   'simon',
   'zorra',
   'peona',
   'halar',
   'abejo',
   'retin',
   'baesa',
   'chano',
   'cargo',
   'lijar',
   'mugre',
   'alijo',
   'pabea',
   'ribat',
   'fisga',
   'chuzo',
   'quiyo',
   'harda',
   'maria',
   'finga',
   'obrar',
   'fijar',
   'tocon',
   'pelon',
   'maqui',
   'anafe',
   'jerpa',
   'avios',
   'madre',
   'capao',
   'oruta',
   'coral',
   'mosto',
   'cojin',
   'sepia',
   'estor',
   'jaula',
   'tirao',
   'adobe',
   'yunta',
   'grupa',
   'puyon',
   'maufo',
   'ofita',
   'cerro',
   'armoa',
   'poleo',
   'caida',
   'ramon',
   'uñero',
   'etico',
   'chozo',
   'bidon',
   'jerez',
   'liuda',
   'cocha',
   'haran',
   'robao',
   'manio',
   'rasar',
   'liuar',
   'vuelo',
   'bayon',
   'canal',
   'aldea',
   'bizco',
   'batir',
   'gonce',
   'sisal',
   'badal',
   'recua',
   'pijar',
   'oruga',
   'miesa',
   'hinco',
   'deber',
   'aerfa',
   'preña',
   'catar',
   'jalar',
   'catea',
   'bolas',
   'largo',
   'minao',
   'alfar',
   'anden',
   'borro',
   'guiro',
   'betin',
   'gavia',
   'honda',
   'jarra',
   'comer',
   'hazao',
   'pegua',
   'trama',
   'ansar',
   'curro',
   'sauco',
   'viena',
   'fardo',
   'tordo',
   'boyal',
   'marco',
   'rodal',
   'harpa',
   'cerca',
   'posio',
   'grava',
   'murta',
   'roiyo',
   'betis',
   'duque',
   'jumar',
   'cidra',
   'corzo',
   'batan',
   'yegua',
   'lluve',
   'velao',
   'presa',
   'fango',
   'mocha',
   'nagua',
   'albar',
   'madri',
   'rozar',
   'gañan',
   'oraza',
   'azoge',
   'focha',
   'gueva',
   'rezno',
   'maina',
   'ehero',
   'aguao',
   'ganso',
   'noria',
   'tarro',
   'prado',
   'cursi',
   'poner',
   'uvate',
   'blusa',
   'cesta',
   'chopo',
   'breca',
   'cobra',
   'parva',
   'mandi',
   'erial',
   'legon',
   'melva',
   'colar',
   'lagar',
   'canco',
   'lando',
   'plana',
   'guija',
   'fuego',
   'pilar',
   'bujeo',
   'zumba',
   'palpi',
   'entuo',
   'hipar',
   'zospe',
   'picha',
   'julai',
   'horao',
   'pilon',
   'draga',
   'barra',
   'pairo',
   'cazar',
   'basca',
   'junco',
   'hueso',
   'ostia',
   'casta',
   'paron',
   'foyao',
   'heria',
   'arazu',
   'torna',
   'butre',
   'petra',
   'jarca',
   'uncir',
   'meter',
   'ariyo',
   'lodar',
   'bodon',
   'piera',
   'tolmo',
   'queso',
   'perla',
   'alfan',
   'cauce',
   'tomar',
   'hacha',
   'cerco',
   'ancha',
   'mogon',
   'curda',
   'tejon',
   'palma',
   'rodeo',
   'manta',
   'tajon',
   'rolla',
   'arcon',
   'nuevo',
   'choto',
   'algar',
   'orujo',
   'bogia',
   'majar',
   'cuete',
   'moñua',
   'bujio',
   'zanja',
   'tanza',
   'duela',
   'colao',
   'panda',
   'pasta',
   'pario',
   'brezo',
   'picar',
   'ricia',
   'zauco',
   'sello',
   'arbol',
   'turon',
   'moles',
   'capea',
   'rajar',
   'colla',
   'rezar',
   'disco',
   'alaja',
   'janga',
   'recao',
   'mecer',
   'morao',
   'mujol',
   'negar',
   'cazon',
   'antra',
   'pinar',
   'aceña',
   'zarpa',
   'pelea',
   'horno',
   'chino',
   'lañao',
   'crudo',
   'cieno',
   'avion',
   'corva',
   'pinta',
   'perna',
   'aviar',
   'burro',
   'pedro',
   'plata',
   'rucho',
   'huron',
   'cerdo',
   'tajea',
   'cañon',
   'suaze',
   'boyar',
   'gordo',
   'lacha',
   'grada',
   'parea',
   'cerna',
   'trapo',
   'cisco',
   'gamon',
   'olivo',
   'albur',
   'armar',
   'momia',
   'pisar',
   'galga',
   'fanga',
   'berza',
   'breña',
   'greña',
   'raban',
   'busel',
   'choca',
   'brisa',
   'casar',
   'barca',
   'mojon',
   'fraja',
   'braza',
   'azuda',
   'chapo',
   'pajel',
   'manga',
   'buche',
   'latas',
   'nariz',
   'jopeo',
   'ruano',
   'comba',
   'bozal',
   'mingo',
   'nacla',
   'miaja',
   'atajo',
   'churi',
   'bucha',
   'gorfo',
   'bebio',
   'jaron',
   'jaral',
   'sarna',
   'miera',
   'galan',
   'porra',
   'tripa',
   'yueca',
   'peñon',
   'galgo',
   'sauce',
   'juron',
   'turca',
   'ovejo',
   'broma',
   'baina',
   'eriza',
   'mausa',
   'dique',
   'malla',
   'torca',
   'mixto',
   'tieso',
   'judia',
   'fogon',
   'pimpi',
   'venta',
   'pasto',
   'barro',
   'zalea',
   'sueca',
   'alcor',
   'rozon',
   'abano',
   'obeja',
   'arcos',
   'silla',
   'bogas',
   'tunda',
   'drago',
   'aelfa',
   'sobar',
   'zurdo',
   'azada',
   'jacho',
   'gacho',
   'balio',
]
