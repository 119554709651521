//import {Adsense} from '@ctrl/react-adsense';
import {HAY_ADSENSE,ADSENSE_CA,ADSENSE_BANN_TECLADO} from '../../constants/strings'
import banner from '../../media/banner.gif';

export function BannerAdsense() {
  
{/*if (HAY_ADSENSE) {
  return (
    <div className="flex justify-center footer publisobreteclado">
    <Adsense
    client={ADSENSE_CA}
    slot={ADSENSE_BANN_TECLADO}
    style={{display:'inline-block',width:'99%',height:'60px'}}
    format=''
  />
  </div> );
} else {
  return (null);
}*/}

  return (
    <div className="flex justify-center footer bannerpublisobreteclado">
  <a href="https://namba.es/nuestra-carta/?utm_source=wordlecadiz&utm_medium=banner&utm_campaign=wordlexbanner&utm_id=grupoarsenio" target="_blank noreferrer">
      <img 
      src={banner}
      alt="new"
      />          </a>
  </div> );


}
