import { WIN_MESSAGES } from '../../constants/strings'
import moment from 'moment'; 
import { Dialog } from "@headlessui/react";
var mensaje_acierto = WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)];

//const fechaspremio = ["02/06/2022","06/06/2022","08/06/2022","10/06/2022","14/06/2022","16/06/2022","20/06/2022","22/06/2022","24/06/2022","28/06/2022","30/06/2022"];
//const hoyes = moment().format("DD/MM/YYYY");
//const haypremio = fechaspremio.includes(hoyes);
const haypremio = 'yes';

export function MensajeAciertoTop() {
  if (haypremio) {
    return (   
      <Dialog.Title
      as="h3"
      className="text-lg leading-6 text-gray-900"
    >
    {mensaje_acierto}
    </Dialog.Title>
    );  
  } else {
    return (
      <Dialog.Title
      as="h3"
      className="text-lg leading-6 text-gray-900"
    >
    Has ganado
    </Dialog.Title>
  ); }
}

export function MensajeAciertoTop2() {
  if (haypremio) {
    return (   
      <p className="text-lg text-gray-900 font-medium">HAS GANADO UN PREMIO</p>
    );  
  } else {
    return (
      <p className="text-lg text-gray-900 font-medium">{mensaje_acierto}</p>
  ); }
}

export function BannerAcierto() {
  if (haypremio) {
    return (
      <div className="mx-auto mt-1 text-center sm:mt-1 modal-titulo">
      <p className="text-base text-gray-900">
      Recoge tu premio de hoy y canjealo rápido, porque estará solo hoy disponible</p>
<br></br>
    <button
type="button"
className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
onClick={(e) => {
  e.preventDefault();
  window.open('https://www.grupoarsenio.com/premio-chupito-cadiz-wordle/?utm_source=QR&utm_medium=mobile&utm_campaign=premio-wordle-cadiz&utm_content=chupitos', '_blank');
}}
>
Recoger mi premio ahora
</button>
<button
type="button"
className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-100 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none sm:text-sm"
onClick={(e) => {
  e.preventDefault();
  window.open('https://www.grupoarsenio.com/category/cadiz-wordle/?utm_source=wordlecadiz&utm_medium=juego&utm_campaign=visitas-soluciones&utm_id=soluciones', '_blank');
}}
>
+info sobre la palabra del día en el blog
</button>
</div>
    );  
  } else  {
    return (
    <div className="mx-auto mt-3 text-center sm:mt-5 modal-titulo">
         <button
type="button"
className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-100 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none sm:text-sm"
onClick={(e) => {
  e.preventDefault();
  window.open('https://www.grupoarsenio.com/category/cadiz-wordle/?utm_source=wordlecadiz&utm_medium=juego&utm_campaign=visitas-soluciones&utm_id=soluciones', '_blank');
}}
>
+info sobre la palabra del día en el blog
</button>
</div>

  ); }

}

